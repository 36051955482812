<template>
  <zb-login-component/>
</template>
<script>
import ZbLoginComponent from '../modules/zb-login'
export default {
  components: {
    ZbLoginComponent
  }
}
</script>
