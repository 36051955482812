import { mapActions, mapGetters } from 'vuex'
import ZbLoginMixin from './ZbLoginMixin'
import { ServiceFactory } from '@/services/ServiceFactory'
const _authService = ServiceFactory.get('AuthService')
export default {
  name: 'ZbLoginComponent',
  mixins: [ ZbLoginMixin ],
  data () {
    return {
      showPass: false,
      loading: false,
      selectedLang: 'es',
      availableLangs: []
    }
  },
  computed: {
    ...mapGetters({
      currentLang$: 'langModule/currentLang',
      authCredentials$: 'authModule/authCredentials'
    })
  },
  watch: {
    authCredentials$: {
      handler: function (newVal) {
        if (newVal && newVal.user.token) {
          this.loading = false
          this.$router.push({ name: 'admin' })
        }
      },
      immediate: true
    }
  },
  created () {
    const currentLang = this.selectedLang
    this.setLang({ currentLang })
  },
  methods: {
    ...mapActions('langModule', ['setLang']),
    ...mapActions('authModule', ['storeCredentials']),
    loginSubmt () {
      this.loading = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const postData = {
          email: this.loginData.email,
          password: this.loginData.password
        }
        _authService.loginSubmt(postData)
          .then((resp) => {
            console.log(resp)
            if (resp.success) {
              this.storeCredentials({ data: resp })
            }
          })
          .catch((err) => {
            this.$notify({
              type: 'error',
              text: err.msg
            })
            this.loading = false
          })
      } else {
        this.loading = false
      }
    }
  }
}
